// This is all you.

const html = document.querySelector("html, body")
const nav = document.querySelector('nav')
const navH = nav.clientHeight
const showOnScrollElements = document.querySelectorAll('.show-on-scroll')

document.addEventListener("scroll", (e) => {
    if(html.scrollTop > 120)  {
        nav.classList.add("colapsed")
    } else {
        nav.classList.remove("colapsed")
    }
    // Show hidden Elements    
    if (showOnScrollElements != null) {
        showOnScrollElements.forEach(el => {
            if(el.getBoundingClientRect().top <= 300){
                el.classList.add('visible')
            }
        });
    }
})

const toggleBtn = document.querySelector("#menuToggle")
const navLinks = document.querySelectorAll("nav a")

if(toggleBtn != null){
    toggleBtn.addEventListener("click", () => {
        toggleBtn.classList.toggle("active")
        nav.classList.toggle("active")
    })
}

navLinks.forEach(link => {
    link.addEventListener("click", () => {
        if(toggleBtn.classList.contains("active")){
            toggleBtn.classList.toggle("active")
            nav.classList.toggle("active")
        }
    })    
});

const bookingBtn = document.querySelector('.booking-btn')

if(bookingBtn != null){
    bookingBtn.addEventListener("click", () => {
        const widgetBtn = document.querySelector('.simplybook-widget-button')
        widgetBtn.click()
    })
}



